html, body, #root {
  height: 100%;
}

.terms-of-use ol ol {
    list-style-type: lower-latin;
}

.rbt-token-label {
    display: inline;
}

.rbt-token {
    display: inline;
    vertical-align: baseline;
    background-color: #0d6efd;
    color: white;
    border-radius: 0.375rem;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem;
}

.rbt-token > * {
    vertical-align: bottom;
}

.rbt-close-content {
    display: none;
}

.rbt-close.btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
    width: 0.5em;
    height: 0.5em;
    margin: 0.25rem;
}
