@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600;700;800;900&display=swap");

a {
  color: #008ACC; }

a:hover {
  color: #27A6E3; }

body {
  font-family: 'Noto Sans JP', sans-serif;
  background: #f4fafd; }
  body::before {
    content: "";
    width: 16vw;
    height: 16vw;
    max-width: 200px;
    background: url("images/i-remoes.png") no-repeat left top;
    background-size: contain;
    position: fixed;
    left: -4%;
    bottom: 4%;
    z-index: -1;
    opacity: 0.20; }
  body::after {
    content: "";
    width: 32vw;
    height: 32vw;
    max-width: 400px;
    background: url("images/i-remoes.png") no-repeat right top;
    background-size: contain;
    position: fixed;
    right: -4%;
    top: 4%;
    z-index: -1;
    opacity: 0.20; }
  body img {
    max-width: 100%;
    height: auto; }

.form-check-input {
  margin-right: 4px; }

.bg-info {
  background-color: #e7f4fb !important; }

.btn-info {
  background-color: #e7f4fb !important;
  border: 0; }
  .btn-info:hover {
    color: #fff;
    background-color: #009BF8 !important; }

.btn-danger {
  background: #fcd3d7 !important;
  color: #333;
  border: 0; }
  .btn-danger:hover {
    color: #fff;
    background: #f22 !important; }

.btn-outline-dark:hover {
  background: #e6e6e6;
  color: #333; }

.btn-login,
.btn-color1 {
  color: #fff;
  background-color: #008ACC;
  border-color: transparent;
  margin-left: 4px; }
  .btn-login:hover,
  .btn-color1:hover {
    background-color: #e7f4fb;
    border-color: transparent;
    color: black; }

.bg-color1 {
  background-color: #008ACC;
  color: #fff; }
  .bg-color1 a {
    color: #fff; }
  .bg-color1 a:hover {
    color: #fff;
    text-decoration: underline; }

.btn-regi,
.btn-color2 {
  color: #333;
  background-color: #74E878;
  border-color: transparent; }
  .btn-regi:hover,
  .btn-color2:hover {
    background-color: #e7f4fb;
    border-color: transparent;
    color: black; }

.bg-color2 {
  background-color: #74E878; }

.color-blue1 {
  color: #D7F0FF; }

.color-blue2 {
  color: #009BF8; }

.bg-blue1 {
  background-color: #D7F0FF; }

.bg-blue2 {
  background-color: #009BF8; }

.btn-remoes-info {
  border: 1px solid #27A6E3;
  color: #27A6E3; }
  .btn-remoes-info:hover {
    background: #27A6E3;
    color: #fff; }

.btn-remoes-danger {
  border: 1px solid #F00070;
  color: #F00070; }
  .btn-remoes-danger:hover {
    background: #F00070;
    color: #fff; }

.remoes-globalnavi img,
.remoes-globalnavi svg {
  width: 156px; }
.remoes-globalnavi .nav-link:hover {
  color: #009BF8; }
.remoes-globalnavi .dropdown ul {
  padding: 0;
  margin-top: 0.5em; }
  .remoes-globalnavi .dropdown ul li {
    border-bottom: 1px solid #eee; }
    .remoes-globalnavi .dropdown ul li:last-child {
      border-bottom: 0; }
    .remoes-globalnavi .dropdown ul li a {
      padding: 8px 8px; }
  .remoes-globalnavi .dropdown ul .btn-dark {
    background: #666;
    color: #fff; }
.remoes-globalnavi .login li a {
  text-align: center;
  background: #D7F0FF; }
  .remoes-globalnavi .login li a:hover {
    color: #fff;
    background: #009BF8; }
.remoes-globalnavi .badge {
  left: -1em;
  top: -1em; }
.remoes-globalnavi .remoes-user-avator img {
  width: 36px;
  height: 36px; }

.remoes-sitename {
  padding: 1em;
  border-bottom: 1px dashed #009BF8;
  background: #008ACC;
  color: #fff; }
  .remoes-sitename figure {
    background: #fff;
    margin: 0;
    padding: 8px;
    text-align: center; }
    .remoes-sitename figure img {
      width: 50%; }
  .remoes-sitename h1 {
    padding: 24px;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    .remoes-sitename h1 b {
      padding: 0 0 16px; }
      .remoes-sitename h1 b img {
        max-width: 280px; }
    .remoes-sitename h1 em {
      font-size: 1.48rem;
      font-style: normal;
      display: inline-block;
      padding: 4px 8px; }
    .remoes-sitename h1 span {
      font-size: 1.0rem;
      display: block;
      padding: 8px 0 0;
      margin: 0; }

.remoes-h1 {
  font-size: 2.1rem;
  font-weight: 600; }

.remoes-h2 {
  font-size: 1.6rem;
  font-weight: 600; }
  .remoes-h2 span {
    padding: 0 1em;
    font-size: 14px; }

.bi {
  margin-right: 4px; }

.remoes-h3 {
  background: linear-gradient(90deg, #008acc 0%, #74e878 100%);
  color: #fff;
  font-size: 1.2em;
  padding: 8px 1.2rem;
  font-weight: 600;
  margin-bottom: 0; }

.remoes-result-header li a {
  font-size: 0.8rem; }

.remoes-item figure img {
  width: auto; }
.remoes-item h2 {
  font-size: 1.4em; }
.remoes-item .btn-sm {
  font-size: 0.8rem; }
@media (min-width: 768px) {
  .remoes-item figure {
    padding: 0 1em 0 0; } }

.remoes-detail dl {
  border-bottom: 1px dashed #eee;
  padding: 1em 0;
  margin: 0;
  align-items: flex-start; }
.remoes-detail dt {
  padding: 0;
  color: #008ACC; }
.remoes-detail dd {
  margin: 0; }
.remoes-detail dd p {
  padding: 0 0 8px;
  margin: 0; }

.remoes-info dl {
  margin: 0;
  padding: 0 0 1em; }
  .remoes-info dl dt {
    font-size: 0.9em;
    font-weight: 500;
    padding-right: 1em; }
.remoes-info th {
  font-size: 0.8em; }

.remoes-inicial li a {
  min-width: 3em; }

.remoes-anchor {
  display: inline-block;
  padding-top: 70px;
  margin-top: -70px; }

.remoes-content-recommend .remoes-info {
  font-size: 0.9em; }
.remoes-content-recommend .col-md-4 {
  flex: 0 0 32%; }

@media (min-width: 768px) {
  .remoes-info dl {
    display: flex; }

  * {
    scroll-padding: 2em 0 0; } }
@media (max-width: 768px) {
  thead {
    display: none; }

  tr {
    display: block;
    margin-bottom: 1em;
    border-bottom: 2px solid #333; }

  th, td {
    display: block;
    border-bottom: 0; } }
.remoes-user-avator {
  border-radius: 50%;
  flex: 0 0 88px;
  margin-right: 1em;
  background: #fff; }
  .remoes-user-avator img {
    width: 88px;
    height: 88px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%; }

.remoes-news p {
  line-height: 1.75;
  padding-bottom: 1em; }

.remoes-sidekick li {
  padding: 2px 2px 0 0; }
.remoes-sidekick li .btn {
  display: block;
  text-align: left;
  font-size: 0.82rem !important; }
.remoes-sidekick .remoes-inicial ul {
  justify-content: space-between; }
.remoes-sidekick .remoes-inicial li {
  flex: 0 0 20%; }
.remoes-sidekick .remoes-inicial li .btn {
  text-align: center; }

.remoes-sidekick .form-check {
  min-height: 0; }
